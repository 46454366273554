import { Injectable } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject, distinctUntilChanged, filter, combineLatest } from 'rxjs';
import { ExchangeService } from '../exchange/exchange.service';
import type { Chain, ChainData, WsCoinAndGasPrice, WsCoinPrice, WsGasPrice, WsPriceVariation24h } from '@dextools/blockchains';
import { ChainUtil } from '@dextools/blockchains';
import { ChainWebSocketsService } from '../web-sockets/chain-web-sockets.service';

const COMMON_CHANNEL = ':common';
export const MIN_DEXTSCORE_INDEX_BOTS = 60;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly _coinPrice$ = new BehaviorSubject<number | null>(null);
  public readonly coinPrice$ = this._coinPrice$.asObservable().pipe(
    filter((value) => value != null),
    distinctUntilChanged(),
  );

  private readonly _priceVariation24h$ = new BehaviorSubject<number | null>(null);
  public readonly priceVariation24h$ = this._priceVariation24h$.asObservable().pipe(
    filter((value) => value != null),
    distinctUntilChanged(),
  );

  private readonly _gasValue$ = new BehaviorSubject<WsGasPrice | null>(null);
  public readonly gas$: Observable<WsGasPrice> = this._gasValue$.asObservable().pipe(
    filter((value): value is WsGasPrice => value != null),
    distinctUntilChanged((prev, current) => JSON.stringify(prev) === JSON.stringify(current)),
  );

  private _chainData!: ChainData;
  private _currentChain!: Chain;

  private _wsPriceAndGasSubscription: Subscription | null = null;

  public constructor(
    private readonly _webSocketService: ChainWebSocketsService,
    private readonly _exchangeService: ExchangeService,
  ) {
    // noop
  }

  /**
   * Initialization logic necessary to listen to price changes of the chain's main coin (i.e. ETH, BNB, etc) and gas (Ether only).
   *
   * IMPORTANT: This method must be called as soon as possible when the app starts (i.e. AppComponent's constructor)
   *
   * @param chain - Chain whose main coin should be checked for prices changes
   */
  public initialize(chain: Chain) {
    this._listenPriceAndGasValues(chain);
    this._currentChain = chain;
    this._connectToCommonWsChain();
  }

  public getPriceETH() {
    try {
      return this._coinPrice$.getValue() ?? 0;
    } catch {
      return 0;
    }
  }

  private _connectToCommonWsChain() {
    combineLatest([this._webSocketService.isConnected$(), this._exchangeService.chain$.asObservable()]).subscribe(
      ([wsConnected, chain]) => {
        if (!wsConnected && this._wsPriceAndGasSubscription) {
          this._resetCoinPriceData();
          return;
        }
        if (chain !== null && wsConnected && (this._currentChain !== chain || !this._wsPriceAndGasSubscription)) {
          this._currentChain = chain;
          if (this._wsPriceAndGasSubscription) {
            this._resetCoinPriceData();
          }
          this._listenPriceAndGasValues(this._currentChain);
        }
      },
    );
  }

  private _resetCoinPriceData() {
    this._wsPriceAndGasSubscription?.unsubscribe();
    this._wsPriceAndGasSubscription = null;
    this._coinPrice$.next(null);
    this._priceVariation24h$.next(null);
    this._gasValue$.next(null);
  }

  private _listenPriceAndGasValues(chain: Chain) {
    this._chainData = ChainUtil.getChainData(chain);

    this._wsPriceAndGasSubscription = this._webSocketService
      .listenWebSocket<WsCoinAndGasPrice | WsCoinPrice | WsGasPrice | WsPriceVariation24h>(
        `${this._chainData.chainPrefix}${COMMON_CHANNEL}`,
        {
          chain,
        },
      )
      .subscribe({
        next: (data) => {
          if (this._isFirstEmission(data)) {
            if (data.ethPrice && this._isCoinPriceEmission(data.ethPrice) && data.ethPrice.ethPriceUsd != null) {
              this._coinPrice$.next(data.ethPrice.ethPriceUsd);
            }
            if (
              data.ethPriceVariation24h &&
              this._isPriceVariation24hEmission(data.ethPriceVariation24h) &&
              data.ethPriceVariation24h.variation24h != null
            ) {
              this._priceVariation24h$.next(data.ethPriceVariation24h.variation24h);
            }
            this._gasValue$.next(data.gasPrice);
            return;
          }

          if (this._isCoinPriceEmission(data)) {
            this._coinPrice$.next(data.ethPriceUsd);
            return;
          }

          if (this._isPriceVariation24hEmission(data)) {
            this._priceVariation24h$.next(data.variation24h);
            return;
          }

          if (this._isGasPriceEmission(data)) {
            this._gasValue$.next(data);
          }
        },
        error: (error) => {
          this._coinPrice$.error(`CoinPrice: could not connect to real-time data source. ${error}`);
          this._priceVariation24h$.error(`PriceVariation24h: could not connect to real-time data source. ${error}`);
          this._gasValue$.error(`GasValue: could not connect to real-time data source. ${error}`);
        },
      });
  }

  private _isFirstEmission(
    emittedValue: WsGasPrice | WsCoinPrice | WsCoinAndGasPrice | WsPriceVariation24h,
  ): emittedValue is WsCoinAndGasPrice {
    return 'ethPrice' in emittedValue;
  }

  private _isGasPriceEmission(
    emittedValue: WsGasPrice | WsCoinPrice | WsCoinAndGasPrice | WsPriceVariation24h,
  ): emittedValue is WsGasPrice {
    return 'safeGasPrice' in emittedValue;
  }

  private _isCoinPriceEmission(
    emittedValue: WsGasPrice | WsCoinPrice | WsCoinAndGasPrice | WsPriceVariation24h,
  ): emittedValue is WsCoinPrice {
    return 'ethPriceUsd' in emittedValue;
  }

  private _isPriceVariation24hEmission(
    emittedValue: WsGasPrice | WsCoinPrice | WsCoinAndGasPrice | WsPriceVariation24h,
  ): emittedValue is WsPriceVariation24h {
    return 'variation24h' in emittedValue;
  }
}
